import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from './config/supabaseClient';

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#2c3e50',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  form: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  input: {
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
  },
  button: {
    padding: '10px',
    backgroundColor: '#2c3e50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

const UpdatePlanPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    const fetchPlan = async () => {
      const { data, error } = await supabase.from('plans').select('name, price').eq('id', id).single();
      if (error) {
        console.error('Error fetching plan:', error);
      } else {
        setName(data.name);
        setPrice(data.price);
      }
    };

    fetchPlan();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error } = await supabase.from('plans').update({ name, price: parseFloat(price) }).eq('id', id);
    if (error) {
      console.error('Error updating plan:', error);
    } else {
      navigate('/plans');
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>Update Plan</h1>
      </header>
      <form style={styles.form} onSubmit={handleSubmit}>
        <input
          style={styles.input}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Plan Name"
          required
        />
        <input
          style={styles.input}
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Price"
          required
        />
        <button style={styles.button} type="submit">
          Update Plan
        </button>
      </form>
    </div>
  );
};

export default UpdatePlanPage;
