import React from 'react';
import Login from './login';
import Home from './Home';
import DomainsPage from './domains';
import PlansPage from './PlansPage';
import PlanDetails from './PlanDetails';
import CreatePlanPage from './CreatePlanPage';
import UpdatePlanPage from './UpdatePlanPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="app-container">
        <div className="content">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />\
            <Route path="/domains" element={<DomainsPage />} />
            <Route path="/plans" element={<PlansPage />} />
            <Route path="/plan/:id" element={<PlanDetails />} />
            <Route path="/plans/create" element={<CreatePlanPage />} />
            <Route path="/plans/update/:id" element={<UpdatePlanPage />} />
            </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

