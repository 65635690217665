import React, { useEffect, useState } from 'react';
import supabase from './config/supabaseClient';

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  tableContainer: {
    overflowX: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    marginTop: '10px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '0',
    backgroundColor: '#ffffff',
  },
  th: {
    backgroundColor: '#2c3e50',
    color: 'white',
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  td: {
    border: '1px solid #ddd',
    padding: '10px',
    color: '#555',
    textAlign: 'left',
  },
  rowHover: {
    transition: 'background-color 0.2s',
  },
  link: {
    color: '#3498db',
    textDecoration: 'none',
  },
  linkHover: {
    textDecoration: 'underline',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
  button: {
    padding: '6px 12px',
    fontSize: '14px',
    margin: '0 5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  deleteButton: {
    backgroundColor: '#e74c3c',
    color: 'white',
  },
  updateButton: {
    backgroundColor: '#3498db',
    color: 'white',
  },
  input: {
    padding: '5px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    width: '100%',
  },
  formContainer: {
    marginBottom: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  formInput: {
    margin: '10px 0',
    padding: '10px',
    fontSize: '16px',
    width: '80%',
    borderRadius: '5px',
    border: '1px solid #ddd',
  },
  formButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#2ecc71',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  select: {
    padding: '5px 10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    marginLeft: '10px',
  },
};

const DomainsPage = () => {
  const [domains, setDomains] = useState([]);
  const [error, setError] = useState(null);

  // State to track the row being edited
  const [editingDomain, setEditingDomain] = useState(null);

  // State for the form inputs
  const [newDomain, setNewDomain] = useState({ name: '', url: 'https://' });

  // State to track form errors
  const [formError, setFormError] = useState('');

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const { data, error } = await supabase.from('domains').select('name, url');
        if (error) throw error;

        setDomains(data || []); // Ensure data is never null or undefined
      } catch (err) {
        setError(err.message);
      }
    };

    fetchDomains();
  }, []);

  // Handle domain deletion
  const handleDelete = async (name) => {
    try {
      const { error } = await supabase.from('domains').delete().eq('name', name);
      if (error) throw error;

      // Remove deleted domain from local state
      setDomains(domains.filter(domain => domain.name !== name));
    } catch (err) {
      setError(err.message);
    }
  };

  // Handle changes in the editable row
  const handleEditChange = (e, name, field) => {
    setDomains(domains.map(domain =>
      domain.name === name ? { ...domain, [field]: e.target.value } : domain
    ));
  };

  // Save edited domain to the database
  const handleSave = async (name) => {
    const domainToUpdate = domains.find(domain => domain.name === name);
    try {
      const { error } = await supabase.from('domains').update(domainToUpdate).eq('name', name);
      if (error) throw error;

      alert('Domain updated!');
      
      // After saving, stop editing this row and make it non-editable
      setEditingDomain(null);
    } catch (err) {
      setError(err.message);
    }
  };

  // Toggle between edit and save mode for each domain
  const handleEditClick = (name) => {
    if (editingDomain === name) {
      // If already editing this domain, do nothing
      return;
    }
    setEditingDomain(name);
  };

  // Handle adding a new domain
  const handleAddDomain = async (e) => {
    e.preventDefault();

    if (!newDomain.name || !newDomain.url) {
      setFormError('Both fields are required.');
      return;
    }

    try {
      const { data, error } = await supabase.from('domains').insert([newDomain]);
      if (error) throw error;

      // Fetch the updated domains after insertion to refresh the table
      const { data: updatedDomains, error: fetchError } = await supabase.from('domains').select('name, url');
      if (fetchError) throw fetchError;

      // Update the domains list with the newly added domain and fetched data
      setDomains(updatedDomains || []);

      // Clear the form and error
      setNewDomain({ name: '', url: 'https://' });
      setFormError('');
    } catch (err) {
      setError(err.message);
    }
  };

  // Handle change in protocol selector
  const handleProtocolChange = (e) => {
    setNewDomain({ ...newDomain, url: `${e.target.value}://${newDomain.url.split('://')[1] || ''}` });
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Domains List</h1>
      {error && <p style={styles.error}>Error: {error}</p>}

      {/* Add Domain Form */}
      <div style={styles.formContainer}>
        <h2>Add New Domain</h2>
        {formError && <p style={styles.error}>{formError}</p>}
        <form onSubmit={handleAddDomain}>
          <input
            type="text"
            placeholder="Enter domain name"
            value={newDomain.name}
            onChange={(e) => setNewDomain({ ...newDomain, name: e.target.value })}
            style={styles.formInput}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              placeholder="Enter domain URL"
              value={newDomain.url}
              onChange={(e) => setNewDomain({ ...newDomain, url: e.target.value })}
              style={styles.formInput}
            />
            <select
              value={newDomain.url.startsWith('https://') ? 'https' : 'http'}
              onChange={handleProtocolChange}
              style={styles.select}
            >
              <option value="https">https</option>
              <option value="http">http</option>
            </select>
          </div>
          <button type="submit" style={styles.formButton}>Add Domain</button>
        </form>
      </div>

      {/* Domains Table */}
      <div style={styles.tableContainer}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Name</th>
              <th style={styles.th}>URL</th>
              <th style={styles.th}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {domains.map((domain) => (
              <tr
                key={domain.name}
                style={styles.rowHover}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#f9f9f9';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = 'transparent';
                }}
              >
                <td style={styles.td}>
                  {editingDomain === domain.name ? (
                    <input
                      type="text"
                      value={domain.name}
                      onChange={(e) => handleEditChange(e, domain.name, 'name')}
                      style={styles.input}
                    />
                  ) : (
                    domain.name
                  )}
                </td>
                <td style={styles.td}>
                  {editingDomain === domain.name ? (
                    <input
                      type="text"
                      value={domain.url}
                      onChange={(e) => handleEditChange(e, domain.name, 'url')}
                      style={styles.input}
                    />
                  ) : (
                    <a
                      href={domain.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={styles.link}
                    >
                      {domain.url}
                    </a>
                  )}
                </td>
                <td style={styles.td}>
                  {editingDomain === domain.name ? (
                    <>
                      <button
                        onClick={() => handleSave(domain.name)}
                        style={{ ...styles.button, ...styles.updateButton }}
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => handleEditClick(domain.name)}
                      style={{ ...styles.button, ...styles.updateButton }}
                    >
                      Edit
                    </button>
                  )}
                  <button
                    onClick={() => handleDelete(domain.name)}
                    style={{ ...styles.button, ...styles.deleteButton }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DomainsPage;
