/*
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './config/supabaseClient';

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#2c3e50',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#2c3e50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  th: {
    backgroundColor: '#34495e',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
    border: '1px solid #ddd',
  },
  td: {
    padding: '10px',
    border: '1px solid #ddd',
  },
};

const PlansPage = () => {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      const { data, error } = await supabase.from('plans').select('id, name, price');
      if (error) {
        console.error('Error fetching plans:', error);
      } else {
        setPlans(data);
      }
    };

    fetchPlans();
  }, []);

  const handleCreateNewPlan = () => {
    navigate('/plans/create');
  };

  const handleNavigate = (id) => {
    navigate(`/plan/${id}`);
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>Plans</h1>
      </header>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleCreateNewPlan}>
          Create New Plan
        </button>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Price</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan) => (
            <tr key={plan.id}>
              <td style={styles.td}>{plan.name}</td>
              <td style={styles.td}>${plan.price}</td>
              <td style={styles.td}>
                <button
                  style={styles.button}
                  onClick={() => handleNavigate(plan.id)}
                >
                  View Plan
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlansPage;
*/
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './config/supabaseClient';

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#2c3e50',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  button: {
    padding: '8px 12px',
    backgroundColor: '#2c3e50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  deleteButton: {
    backgroundColor: '#e74c3c',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  th: {
    backgroundColor: '#34495e',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
    border: '1px solid #ddd',
  },
  td: {
    padding: '10px',
    border: '1px solid #ddd',
    textAlign: 'center',
  },
};

const PlansPage = () => {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      const { data, error } = await supabase.from('plans').select('id, name, price');
      if (error) console.error('Error fetching plans:', error);
      else setPlans(data);
    };
    fetchPlans();
  }, []);

  const handleNavigate = (id) => navigate(`/plan/${id}`);

  const handleUpdate = (id) => navigate(`/plans/update/${id}`);

  const handleDelete = async (id) => {
    const { error } = await supabase.from('plans').delete().eq('id', id);
    if (error) console.error('Error deleting plan:', error);
    else setPlans(plans.filter((plan) => plan.id !== id));
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>Plans</h1>
      </header>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={() => navigate('/plans/create')}>
          Create New Plan
        </button>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Price</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan) => (
            <tr key={plan.id}>
              <td style={styles.td}>{plan.name}</td>
              <td style={styles.td}>${plan.price}</td>
              <td style={styles.td}>
                <button style={styles.button} onClick={() => handleNavigate(plan.id)}>
                  View
                </button>
                <button style={styles.button} onClick={() => handleUpdate(plan.id)}>
                  Update
                </button>
                <button
                  style={{ ...styles.button, ...styles.deleteButton }}
                  onClick={() => handleDelete(plan.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlansPage;
