/*
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import supabase from './config/supabaseClient';

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#2c3e50',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  productList: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  productItem: {
    marginBottom: '10px',
    padding: '10px',
    borderBottom: '1px solid #ddd',
    fontSize: '16px',
  },
  productName: {
    fontWeight: 'bold',
  },
};

const PlanDetails = () => {
  const { id } = useParams(); // Plan ID from the URL
  const [plan, setPlan] = useState(null); // Stores plan details
  const [products, setProducts] = useState([]); // Stores associated products

  useEffect(() => {
    const fetchPlanDetails = async () => {
      // Fetch plan details
      const { data: planData, error: planError } = await supabase
        .from('plans')
        .select('id, name, price')
        .eq('id', id)
        .single();

      if (planError) {
        console.error('Error fetching plan details:', planError);
        return;
      }

      // Fetch associated products via prod_plans
      const { data: prodPlansData, error: prodPlansError } = await supabase
        .from('prod_plans')
        .select('id_product')
        .eq('id_plan', id);

      if (prodPlansError) {
        console.error('Error fetching prod_plans:', prodPlansError);
        return;
      }

      // Get product details for associated products
      const productIds = prodPlansData.map((relation) => relation.id_product);
      const { data: productsData, error: productsError } = await supabase
        .from('products')
        .select('id, name')
        .in('id', productIds);

      if (productsError) {
        console.error('Error fetching products:', productsError);
        return;
      }

      setPlan(planData);
      setProducts(productsData);
    };

    fetchPlanDetails();
  }, [id]);

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>Plan Details</h1>
      </header>
      {plan ? (
        <div>
          <h2>{plan.name}</h2>
          <p>Price: ${plan.price}</p>

          <div style={styles.productList}>
            <h3>Associated Products</h3>
            {products.length > 0 ? (
              products.map((product) => (
                <div key={product.id} style={styles.productItem}>
                  <span style={styles.productName}>{product.name}</span>
                </div>
              ))
            ) : (
              <p>No products associated with this plan.</p>
            )}
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default PlanDetails;
*/

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import supabase from './config/supabaseClient';

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#2c3e50',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '20px',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: 'white',
  },
  th: {
    backgroundColor: '#34495e',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
    border: '1px solid #ddd',
  },
  td: {
    padding: '10px',
    border: '1px solid #ddd',
    textAlign: 'center',
  },
  actionButton: {
    padding: '5px 10px',
    backgroundColor: '#e74c3c',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  addProductContainer: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  select: {
    padding: '10px',
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    fontSize: '16px',
    color: '#333',
    outline: 'none',
    transition: 'border-color 0.2s ease-in-out',
  },
  selectHover: {
    borderColor: '#3498db',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#2c3e50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

const PlanDetails = () => {
  const { id } = useParams();
  const [plan, setPlan] = useState(null);
  const [associatedProducts, setAssociatedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  useEffect(() => {
    const fetchPlanDetails = async () => {
      const { data, error } = await supabase
        .from('plans')
        .select('*')
        .eq('id', id)
        .single();

      if (error) console.error('Error fetching plan details:', error);
      else setPlan(data);
    };

    const fetchAssociatedProducts = async () => {
      const { data, error } = await supabase
        .from('prod_plans')
        .select('id, id_product, products(name)')
        .eq('id_plan', id);

      if (error) console.error('Error fetching associated products:', error);
      else setAssociatedProducts(data);
    };

    const fetchAllProducts = async () => {
      const { data, error } = await supabase.from('products').select('*');

      if (error) console.error('Error fetching products:', error);
      else setAllProducts(data);
    };

    fetchPlanDetails();
    fetchAssociatedProducts();
    fetchAllProducts();
  }, [id]);

  const handleAssociateProduct = async () => {
    if (!selectedProduct) return;
  
    const { error } = await supabase.from('prod_plans').insert({
      id_plan: id,
      id_product: selectedProduct,
    });
  
    if (error) {
      console.error('Error associating product:', error);
    } else {
      // After successfully associating, fetch the latest associated products
      fetchAssociatedProducts();
  
      // Clear the selected product
      setSelectedProduct('');
    }
  };
  
  // Function to fetch associated products
  const fetchAssociatedProducts = async () => {
    const { data, error } = await supabase
      .from('prod_plans')
      .select('id, id_product, products(name)')
      .eq('id_plan', id);
  
    if (error) {
      console.error('Error fetching associated products:', error);
    } else {
      setAssociatedProducts(data);
    }
  };
  

  const handleDeleteConnection = async (connectionId) => {
    const { error } = await supabase.from('prod_plans').delete().eq('id', connectionId);

    if (error) {
      console.error('Error deleting connection:', error);
    } else {
      setAssociatedProducts((prev) =>
        prev.filter((product) => product.id !== connectionId)
      );
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>Plan Details</h1>
      </header>
      {plan && (
        <div style={styles.section}>
          <h2>{plan.name}</h2>
          <p>Price: ${plan.price}</p>
        </div>
      )}
      <div style={styles.section}>
        <h3>Associated Products</h3>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Product Name</th>
              <th style={styles.th}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {associatedProducts.map((product) => (
              <tr key={product.id}>
                <td style={styles.td}>{product.products.name}</td>
                <td style={styles.td}>
                  <button
                    style={styles.actionButton}
                    onClick={() => handleDeleteConnection(product.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ ...styles.section, ...styles.addProductContainer }}>
        <h3>Associate a New Product</h3>
        <select
          style={styles.select}
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
        >
          <option value="">Select a product</option>
          {allProducts.map((product) => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
        </select>
        <button style={styles.button} onClick={handleAssociateProduct}>
          Associate Product
        </button>
      </div>
    </div>
  );
};

export default PlanDetails;
