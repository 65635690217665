import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import supabase from './config/supabaseClient'; // Import the Supabase client

const styles = {
  dashboard: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#2c3e50',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
};

const Home = () => {
  const [domainCount, setDomainCount] = useState(null);

  useEffect(() => {
    // Fetch the number of rows in the "domains" table
    const fetchDomainCount = async () => {
      const { data, error, count } = await supabase
        .from('domains')
        .select('*', { count: 'exact', head: true });

      if (error) {
        console.error('Error fetching domain count:', error);
      } else {
        setDomainCount(count);
      }
    };

    fetchDomainCount();
  }, []);

  return (
    <div style={styles.dashboard}>
      <header style={styles.header}>
        <h1>Dashboard</h1>
      </header>
      <div style={styles.content}>
        <div style={styles.row}>
          <GraphBox
            title="Manage Domains"
            to="/domains"
            count={domainCount !== null ? `${domainCount} Active Domains` : 'Loading...'}
          />
          <GraphBox title="Subscriptions" to="/another" />
        </div>
        <div style={styles.row}>
          <GraphBox title="Plans" to="/plans" />
          <GraphBox title="Products" to="/another" />
        </div>
        <div style={styles.row}>
          <GraphBox title="Graph" to="/test" />
          <GraphBox title="Documentation" to="/another" />
        </div>
      </div>
    </div>
  );
};

const GraphBox = ({ title, to, count }) => {
  const boxStyles = {
    flex: 1,
    minHeight: '150px',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    textDecoration: 'none',
    color: 'inherit',
    transition: 'transform 0.2s, box-shadow 0.2s',
    cursor: 'pointer',
  };

  const placeholderStyles = {
    height: '100px',
    backgroundColor: '#e0e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: '#555',
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.transform = 'scale(1.05)';
    e.currentTarget.style.boxShadow = '0 6px 10px rgba(0, 0, 0, 0.2)';
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.transform = 'scale(1)';
    e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
  };

  return (
    <Link
      to={to}
      style={boxStyles}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <h3>{title}</h3>
      <div style={placeholderStyles}>
        <p>{count || 'Graph Placeholder'}</p>
      </div>
    </Link>
  );
};

export default Home;
